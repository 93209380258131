<template>
    <label class="body-2 text-typo font-weight-bold ms-1">
        <slot/>
    </label>
</template>

<script>
    export default {}
</script>

<style lang="stylus">
</style>
