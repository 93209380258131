var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('container-card',[_c('v-data-table',{attrs:{"headers":_vm.columns,"items":_vm.items,"server-items-length":_vm.totalItems,"options":_vm.pagination,"footer-props":_vm.footerProps,"search":_vm.search,"loading":_vm.loading},on:{"update:options":[function($event){_vm.pagination=$event},_vm.alterOptions],"click:row":_vm.clickItem},scopedSlots:_vm._u([{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("datetime")(item.createdAt))+" ")]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"font-color-white",attrs:{"color":_vm.colorByType(item),"small":""}},[_vm._v(" "+_vm._s(_vm.typeName(item))+" ")])]}},{key:"item.target",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"color":_vm.colorByType(item),"small":"","variant":"flat"}},[_vm._v(_vm._s(_vm.targetIcon(item)))]),_vm._v(" "+_vm._s(_vm.targetName(item))+" ")]}},{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("value")(_vm.hideByType(item)))+" ")]}},{key:"item.productCost",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("real")(item.productCost))+" ")]}},{key:"item.liquidRate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("real")(item.liquidRate))+" ")]}},{key:"item.value",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("real")(item.value))+" ")]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('a',{on:{"click":function($event){return _vm.$emit('view',item.id)}}},[_c('v-icon',[_vm._v("fa-angle-right")])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }