var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('container-card',[_c('v-data-table',{staticClass:"clickable",attrs:{"headers":_vm.columns,"hide-default-footer":_vm.items.length < _vm.pagination.itemsPerPage,"items":_vm.items,"options":_vm.pagination,"search":_vm.search,"loading":_vm.loading},on:{"click:row":_vm.clickRow},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("status")(item.status))+" ")]}},{key:"item.amount",fn:function(ref){
var item = ref.item;
return [(item.showAmount)?_c('span',[_vm._v(_vm._s(_vm._f("value")(item.amount,item.quantityDecimalPlacesAmount)))]):_vm._e()]}},{key:"item.cost",fn:function(ref){
var item = ref.item;
return [(item.showCost)?_c('span',[_vm._v(_vm._s(_vm._f("real")(item.cost,item.quantityDecimalPlacesValue)))]):_vm._e()]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-icon',[_vm._v("fa-angle-right")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }