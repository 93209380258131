var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('az-container',[_c('container-card',{attrs:{"title":"Estoque Geral","icon":"fa-light fa-money-bill-1-wave"}},[_c('v-data-table',{attrs:{"headers":_vm.columns,"items":_vm.items,"options":_vm.pagination,"loading":_vm.loading,"hide-default-footer":_vm.items.length < _vm.pagination.itemsPerPage},scopedSlots:_vm._u([{key:"item.amount",fn:function(ref){
var item = ref.item;
return [(item.showAmount)?_c('span',[_vm._v(" "+_vm._s(_vm._f("value")(item.amount,item.quantityDecimalPlacesAmount))+" ")]):_vm._e()]}},{key:"item.reservedAmount",fn:function(ref){
var item = ref.item;
return [(item.showAmount)?_c('span',[_vm._v(" "+_vm._s(_vm._f("value")(item.reservedAmount,item.quantityDecimalPlacesAmount))+" ")]):_vm._e()]}},{key:"item.cost",fn:function(ref){
var item = ref.item;
return [(item.showCost)?_c('span',[_vm._v(_vm._s(_vm._f("real")(item.cost,item.quantityDecimalPlacesValue)))]):_vm._e()]}},{key:"item.total",fn:function(ref){
var item = ref.item;
return [(item.showAmount && item.showCost)?_c('span',[_vm._v(_vm._s(_vm._f("real")(_vm.calcTotal(item))))]):_vm._e()]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }