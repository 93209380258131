export default {
    LOKI: {
        DISABLE_GLOBAL_LOADING: 'disableGlobalLoading',
        ENABLE_GLOBAL_LOADING: 'enableGlobalLoading',
        SET_GLOBAL_LOADING: 'setGlobalLoading',
        SET_LOADING_MESSAGE: 'setLoadingMessage',
        SET_MENU_ACTIONS: 'SET_MENU_ACTIONS',
        SET_NOTIFICATION: 'setNotification',
        SHOW_ALERT: 'showAlert',
        DISABLE_AUTO_SAVING: 'DISABLE_AUTO_SAVING',
        ENABLE_AUTO_SAVING: 'ENABLE_AUTO_SAVING',
        SET_TIMEZONE: 'setTimezone',
        SET_ASIDE_HIDE: 'SET_ASIDE_HIDE',
    },
    COMMON: {
        SET_EXPAND_MENU: 'SET_EXPAND_MENU',
        SET_AVATAR_ACTIONS: 'SET_AVATAR_ACTIONS',
        SET_PRODUCT: 'SET_PRODUCT',
        SET_RETRACT_MENU: 'SET_RETRACT_MENU',
        SET_LOGGED_USER: 'SET_LOGGED_USER',
        SET_AUTH_TOKEN: 'SET_AUTH_TOKEN',
        SET_AUTH_REFRESH_TOKEN: 'SET_AUTH_REFRESH_TOKEN',
        SET_COMPANY: 'SET_COMPANY',
        SET_COMPANIES: 'SET_COMPANIES',
    },
    CUSTOMER: {
        SET_FILTER: 'CUSTOMER.SET_FILTER',
        SET_PAGINATION: 'CUSTOMER.SET_PAGINATION',
    },
    MOVEMENT: {
        SET_FILTER: 'MOVEMENT.SET_FILTER',
        SET_PAGINATION: 'MOVEMENT.SET_PAGINATION',
        SET_RESERVATION_PAGINATION: 'MOVEMENT.SET_RESERVATION_PAGINATION',
    },
    CLOSURE: {
        SET_FILTER: 'CLOSURE.SET_FILTER',
        SET_PAGINATION: 'CLOSURE.SET_PAGINATION',
    }
}
