const PRODUCT = 'PRODUCT'
const ACCOUNT = 'ACCOUNT'

export default {
    PRODUCT,
    ACCOUNT,
    list: [
        {
            name: 'Produto',
            value: PRODUCT
        },
        {
            name: 'Conta',
            value: ACCOUNT
        }
    ]
}
