export default {
    list: [
        {
            name: 'Sim',
            value: true
        },
        {
            name: 'Não',
            value: false
        }
    ]
}
