export default [
  {
    nome: 'Janeiro',
    valor: 0
  },
  {
    nome: 'Fevereiro',
    valor: 1
  },
  {
    nome: 'Março',
    valor: 2
  },
  {
    nome: 'Abril',
    valor: 3
  },
  {
    nome: 'Maio',
    valor: 4
  },
  {
    nome: 'Junho',
    valor: 5
  },
  {
    nome: 'Julho',
    valor: 6
  },
  {
    nome: 'Agosto',
    valor: 7
  },
  {
    nome: 'Setembro',
    valor: 8
  },
  {
    nome: 'Outubro',
    valor: 9
  },
  {
    nome: 'Novembro',
    valor: 10
  },
  {
    nome: 'Dezembro',
    valor: 11
  }
]
