export default {
    ACCOUNT: {
        FIND_ALL: 'ACCOUNT.FIND_ALL',
        FIND_ALL_COMPLETE: 'ACCOUNT.FIND_ALL_COMPLETE',
        FIND_BY_ID: 'ACCOUNT.FIND_BY_ID',
        SAVE: 'ACCOUNT.SAVE',
        REMOVE: 'ACCOUNT.REMOVE'
    },
    COMPANY: {
        FIND_ALL: 'COMPANY.FIND_ALL',
        FIND_BY_ID: 'COMPANY.FIND_BY_ID',
        SAVE: 'COMPANY.SAVE'
    },
    INSTALLMENT: {
        CALC: 'INSTALLMENT.CALC'
    },
    COMMON: {
        SEARCH_COMPANY: 'COMMON.SEARCH_COMPANY',
        CHANGE_COMPANY: 'COMMON.CHANGE_COMPANY',
        FIND_ALL_COMPANIES: 'COMMON.FIND_ALL_COMPANIES',
        SEARCH_LOGGED_USER: 'COMMON.SEARCH_LOGGED_USER',
        LOGIN: 'COMMON.LOGIN',
    },
    CUSTOMER: {
        FIND_ALL: 'CUSTOMER.FIND_ALL',
        ADVANCED_SEARCH: 'CUSTOMER.ADVANCED_SEARCH',
        FIND_BY_ID: 'CUSTOMER.FIND_BY_ID',
        SAVE: 'CUSTOMER.SAVE',
        REMOVE: 'CUSTOMER.REMOVE',
        FIND_ZIP_CODE: 'CUSTOMER.FIND_ZIP_CODE',
        EXPORT_CSV: 'CUSTOMER.EXPORT_CSV',
    },
    MOVEMENT: {
        SAVE: 'MOVEMENT.SAVE',
        FINISH: 'MOVEMENT.FINISH',
        CANCEL: 'MOVEMENT.CANCEL',
        ADVANCED_SEARCH: 'MOVEMENT.ADVANCED_SEARCH',
        FIND_BY_ID: 'MOVEMENT.FIND_BY_ID',
        REMOVE: 'MOVEMENT.REMOVE',
        PRINT: 'MOVEMENT.PRINT',
        FIND_LAST_PRODUCT_INPUT_ID: 'MOVEMENT.FIND_LAST_PRODUCT_INPUT_ID',
    },
    CLOSURE: {
        SAVE: 'CLOSURE.SAVE',
        ADVANCED_SEARCH: 'CLOSURE.ADVANCED_SEARCH',
        FIND_BY_ID: 'CLOSURE.FIND_BY_ID',
        FIND_MOVEMENTS_BY_ID: 'CLOSURE.FIND_MOVEMENTS_BY_ID'
    },
    PRODUCT: {
        GET_PRODUCTS: 'PRODUCT.GET_PRODUCTS',
        FIND_ALL: 'PRODUCT.FIND_ALL',
        FIND_ALL_COMPLETE: 'PRODUCT.FIND_ALL_COMPLETE',
        FIND_ALL_COMPANY: 'PRODUCT.FIND_ALL_COMPANY',
        FIND_BY_ID: 'PRODUCT.FIND_BY_ID',
        FIND_ALL_CATEGORIES: 'PRODUCT.FIND_ALL_CATEGORIES',
        SAVE: 'PRODUCT.SAVE',
        REMOVE: 'PRODUCT.REMOVE',
    },
    PROFIT: {
        FIND_ALL_COMPLETE: 'PROFIT.FIND_ALL_COMPLETE',
    },
    USER: {
        FIND_ALL: 'USER.FIND_ALL',
        FIND_ALL_TO_BALANCE: 'USER.FIND_ALL_TO_BALANCE',
        FIND_BY_ID: 'USER.FIND_BY_ID',
        SAVE: 'USER.SAVE',
    },
    EXPENSE: {
        FIND_ALL: 'EXPENSE.FIND_ALL',
        FIND_ALL_COMPLETE: 'EXPENSE.FIND_ALL_COMPLETE',
        FIND_BY_ID: 'EXPENSE.FIND_BY_ID',
        SAVE: 'EXPENSE.SAVE',
        REMOVE: 'EXPENSE.REMOVE'
    },
}
